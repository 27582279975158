import { defineStore } from 'pinia'
import Axios from 'axios'

const LOCAL_STORAGE_KEY_FAVORITE_PLACE_ID_LIST = 'favoritePlaceIdList'

export const useFavoritePlaceList = defineStore('favoritePlaceList', {
  state: () => ({
    favoritePlaces: [],
    loaded: false,
  }),
  getters: {
    getFavoritePlaces: state => {
      return state.favoritePlaces
    },
    getIsLoaded: state => {
      return state.loaded
    },
  },
  actions: {
    /**
     * kapott place már favorited-e
     *
     * @param {{}} placeData
     * @returns {boolean}
     */
    isFavorite(placeData) {
      return this.favoritePlaces.findIndex(place => place.id === placeData.id) !== -1
    },
    /**
     * favorited place lista betöltése
     */
    load() {
      Axios.post('/api/public/place/listFavoritePlaces', {
        idList: this._getLocalStorageValue(),
      })
        .then(({ data }) => {
          this.favoritePlaces = data
        })
        .catch(() => {
          this.favoritePlaces = []
          console.error('hiba a kedvenc helyszínek betöltése közben!')
        })
        .finally(() => {
          this.loaded = true
        })
    },
    /**
     * place favorite listához adás
     *
     * @param placeData
     */
    add(placeData) {
      if (this.isFavorite(placeData)) {
        return
      }
      this.favoritePlaces = [
        ...this.favoritePlaces,
        placeData,
      ]
      this._updateLocalStorageValue()
    },
    /**
     * place kivétele a favorite listából
     *
     * @param placeData
     */
    remove(placeData) {
      const indexToRemove = this.favoritePlaces.findIndex(place => place.id === placeData.id)
      if (indexToRemove === -1) {
        return
      }
      this.favoritePlaces.splice(indexToRemove, 1)
      this._updateLocalStorageValue()
    },
    /**
     * favorite place id lista betöltése
     *
     * @private
     */
    _updateLocalStorageValue() {
      localStorage.setItem(LOCAL_STORAGE_KEY_FAVORITE_PLACE_ID_LIST, JSON.stringify(this.favoritePlaces.map(place => place.id)))
    },
    /**
     * favorite place id lista mentése
     *
     * @returns {number[]}
     * @private
     */
    _getLocalStorageValue() {
      const favoritePlaceIdListValue = localStorage.getItem(LOCAL_STORAGE_KEY_FAVORITE_PLACE_ID_LIST)
      if (favoritePlaceIdListValue === null) {
        return []
      }
      return JSON.parse(favoritePlaceIdListValue)
    },
  },
})
