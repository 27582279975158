<template>
  <header class="w-100">
    <navigation-header />
  </header>
  <main>
    <router-view />
  </main>
  <footer>
    <main-footer />
  </footer>
</template>

<script>
import pageName from '@/constants/pageName.js'
import NavigationHeader from '@/components/navigation-header/NavigationHeader.vue'
import MainFooter from '@/components/footer/footer.vue'

export default {
  name: 'PublicLayout',
  components: {
    MainFooter,
    NavigationHeader,
  },
  data() {
    return {
      pageName,
    }
  },
}
</script>
