import pageName from '@/constants/pageName.js'

export function useApiErrorHandler() {
  /**
   * Api error handler
   */
  function apiErrorHandler(error) {
    // Notify user on bad request.
    if (error.response !== undefined && error.response.status === 400 && error.response.data.message !== undefined) {
      this.$emitter.emit('notify', {
        message: error.response.data.message,
        type: 'error',
      })
    } else {
      this.$emitter.emit('notify', {
        message: this.$t('generic.api_error'),
        type: 'error',
      })
      console.error(error)
    }
  }

  /**
   * Ha az api 404 válaszra fut, akkor a router a 404 komponenst nyitja meg, de az url-t megőrzi
   *
   * @param error
   * @param router
   * @param route
   */
  function apiNotFoundHandler(error, router, route) {
    if (error.response === undefined) {
      return
    }

    if (error.response.status !== 404) {
      return
    }

    // https://router.vuejs.org/guide/essentials/dynamic-matching.html#Catch-all-404-Not-found-Route
    // push helyett replace, hogy a visszalépés jól működjön (egybéként ugyanoda lép vissza ami a 404-et okozta)
    router.replace({
      name: pageName.PAGE_NOT_FOUND,
      params: { pathMatch: route.path.substring(1).split('/') },
      query: route.query,
      hash: route.hash,
    })
  }

  return {
    apiErrorHandler,
    apiNotFoundHandler,
  }
}
