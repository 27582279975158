/**
 * kézzel szinkronban tartva a Place.php Place::SEARCH_* konstansokkal!
 */
export const SEARCH_TYPES = {
  SEARCH_TYPE_CITY: 'city',
  SEARCH_TYPE_FREETEXT: 'freeText',
  SEARCH_TYPE_REGION: 'region',
  SEARCH_TYPE_FEATURED_CITY: 'featuredCity',
}

/**
 * kézzel szinkronban tartva a Place.php Place::SEARCH_LIMIT_* konstansokkal!
 */
export const SEARCH_LIMIT_TYPES = {
  SEARCH_LIMIT_10: '10',
  SEARCH_LIMIT_MORE_THAN_10: '10+',
  SEARCH_LIMIT_25: '25',
  SEARCH_LIMIT_50: '50',
  SEARCH_LIMIT_100: '100',
  SEARCH_LIMIT_150: '150',
  SEARCH_LIMIT_200: '200',
  SEARCH_LIMIT_250: '250',
  SEARCH_LIMIT_300: '300',
  SEARCH_LIMIT_350: '350',
  SEARCH_LIMIT_400: '400',
  SEARCH_LIMIT_450: '450',
  SEARCH_LIMIT_MORE_THAN_350: '350+',
  SEARCH_LIMIT_MORE_THAN_400: '400+',
  SEARCH_LIMIT_MORE_THAN_450: '450+',
}
