import { createWebHistory, createRouter } from 'vue-router'
import pageName from '@/constants/pageName.js'
import Layout from '@/components/layout/PublicLayout.vue'
import env from '@shared/vite/env.js'

const lang = env.LANG

const pathsByLang = {
  hu: {
    venues: '/rendezvenyhelyszinek/:slug',
    enquiry: '/ajanlatkeres',
    enquiryForPlace: '/ajanlatkeres/:placeId',
    page: '/oldal/:title',
    venueList: '/helyszin-lista/:predefinedListName',
    placesMain: '/helyszinek',
    placesByType: '/helyszinek/tipus/:typeSlug',
    placesByTypeAndCity: '/helyszinek/tipus/:typeSlug/:citySlug',
    placesByNature: '/helyszinek/jelleg/:natureSlug',
    placesByCountry: '/helyszinek/:countrySlug',
    placesByCountryAndRegion: '/helyszinek/:countrySlug/:regionSlug',
    placesByCountryAndRegionAndType: '/helyszinek/:countrySlug/:regionSlug/:typeSlug',
    placeBySlug: '/helyszin/:slug',
    favourite: '/kedvenc-helyszineim',
    articles: '/cikkek',
    articlesByTag: '/cikkek/cimke/:slug',
    articleBySlug: '/cikk/:slug',
    newsletterSubscribe: '/hirlevel-feliratkozas',
    venueRegistration: '/rendezvenyhelyszin-regisztracio',
    contact: '/kapcsolat',
  },
  en: {
    venues: '/event-venues/:slug',
    enquiry: '/enquiry',
    enquiryForPlace: '/enquiry/:placeId',
    page: '/site/:title',
    venueList: '/venue-list/:predefinedListName',
    placesMain: '/places',
    placesByType: '/places/type/:typeSlug',
    placesByTypeAndCity: '/places/type/:typeSlug/:citySlug',
    placesByNature: '/places/nature/:natureSlug',
    placesByCountry: '/places/:countrySlug',
    placesByCountryAndRegion: '/places/:countrySlug/:regionSlug',
    placesByCountryAndRegionAndType: '/places/:countrySlug/:regionSlug/:typeSlug',
    placeBySlug: '/place/:slug',
    favourite: '/favourite-places',
    articles: '/news',
    articlesByTag: '/article/tag/:slug',
    articleBySlug: '/article/:slug',
    newsletterSubscribe: '/newsletter-subscription',
    venueRegistration: '/event-venue-registration',
    contact: '/contact',
  },
}

const routes = [
  {
    component: Layout,
    redirect: {
      name: pageName.HOME,
    },
    children: [
      {
        path: '/',
        name: pageName.HOME,
        component: () => import('@/pages/home/Home.vue'),
      },
      {
        path: '/rendezvenyhelyszin-hirlevel-feliratkozas',
        name: pageName.NEWSLETTER_SUBSCRIBE_PAGE_FOR_PLACE,
        component: () => import('@/pages/newsletter-subscribe-page-for-place/NewsletterSubscribePageForPlace.vue'),
      },
      {
        path: pathsByLang[lang].venues,
        name: pageName.TILED_PAGE,
        component: () => import('@/pages/tiled-page/TiledPage.vue'),
      },
      {
        path: pathsByLang[lang].venueList,
        name: pageName.PLACE_PREDEFINED_LIST,
        component: () => import('@/pages/place-list-page/PlaceListPage.vue'),
      },
      {
        path: pathsByLang[lang].page,
        name: pageName.CONTENT_PAGE,
        component: () => import('@/pages/content-page/ContentPage.vue'),
      },
      {
        path: pathsByLang[lang].enquiry,
        name: pageName.NEW_OFFER_PAGE,
        component: () => import('@/pages/offer/NewOfferPage.vue'),
      },
      {
        path: pathsByLang[lang].enquiryForPlace,
        name: pageName.NEW_OFFER_PAGE_WITH_PLACE_ID,
        component: () => import('@/pages/offer/NewOfferPage.vue'),
      },
      {
        path: pathsByLang[lang].placesMain,
        name: pageName.PLACE_LIST_PAGE_ALL,
        component: () => import('@/pages/place-list-page/PlaceListPage.vue'),
      },
      {
        path: pathsByLang[lang].placesByType,
        name: pageName.PLACE_LIST_PAGE_TYPE,
        component: () => import('@/pages/place-list-page/PlaceListPage.vue'),
      },
      {
        path: pathsByLang[lang].placesByTypeAndCity,
        name: pageName.PLACE_LIST_PAGE_TYPE_CITY,
        component: () => import('@/pages/place-list-page/PlaceListPage.vue'),
      },
      {
        path: pathsByLang[lang].placesByNature,
        name: pageName.PLACE_LIST_PAGE_NATURE,
        component: () => import('@/pages/place-list-page/PlaceListPage.vue'),
      },
      {
        path: pathsByLang[lang].placesByCountry,
        name: pageName.PLACE_LIST_PAGE_COUNTRY,
        component: () => import('@/pages/place-list-page/PlaceListPage.vue'),
      },
      {
        path: pathsByLang[lang].placesByCountryAndRegion,
        name: pageName.PLACE_LIST_PAGE_COUNTRY_REGION,
        component: () => import('@/pages/place-list-page/PlaceListPage.vue'),
      },
      {
        path: pathsByLang[lang].placesByCountryAndRegionAndType,
        name: pageName.PLACE_LIST_PAGE_COUNTRY_REGION_TYPE,
        component: () => import('@/pages/place-list-page/PlaceListPage.vue'),
      },
      {
        path: pathsByLang[lang].placeBySlug,
        name: pageName.PLACE_PAGE,
        component: () => import('@/pages/place/PlacePage.vue'),
      },
      {
        path: pathsByLang[lang].favourite,
        name: pageName.FAVORITE_PLACE_LIST_PAGE,
        component: () => import('@/pages/favorite-place-list-page/FavoritePlaceListPage.vue'),
      },
      {
        path: pathsByLang[lang].articles,
        name: pageName.ARTICLE_LIST_PAGE,
        component: () => import('@/pages/artice-list-page/ArticleListPage.vue'),
      },
      {
        path: pathsByLang[lang].articlesByTag,
        name: pageName.ARTICLE_LIST_WITH_TAG_PAGE,
        component: () => import('@/pages/artice-list-page/ArticleListPage.vue'),
      },
      {
        path: pathsByLang[lang].articleBySlug,
        name: pageName.ARTICLE_PAGE,
        component: () => import('@/pages/article-page/ArticlePage.vue'),
      },
      {
        path: pathsByLang[lang].newsletterSubscribe,
        name: pageName.NEWSLETTER_SUBSCRIBE_PAGE,
        component: () => import('@/pages/newsletter-subscribe-page/NewsletterSubscribePage.vue'),
      },
      {
        path: pathsByLang[lang].venueRegistration,
        name: pageName.PLACE_REGISTRATION_PAGE,
        component: () => import('@/pages/place-registration-page/PlaceRegistrationPage.vue'),
      },
      {
        // TODO: minek url+page ehhez?
        path: '/sikeres-rendezvenyhelyszin-regisztracio',
        name: pageName.PLACE_REGISTRATION_THANK_YOU_PAGE,
        component: () => import('@/pages/place-registration-thank-you-page/PlaceRegistrationThankYouPage.vue'),
      },
      {
        path: pathsByLang[lang].contact,
        name: pageName.TEAM_PAGE,
        component: () => import('@/pages/team-page/TeamPage.vue'),
      },
      {
        path: '/place/edit/:hash',
        name: pageName.PLACE_EDIT,
        component: () => import('@/pages/place/PlaceEdit.vue'),
      },
      {
        path: '/:pathMatch(.*)*',
        name: pageName.PAGE_NOT_FOUND,
        component: () => import('@/pages/page-not-found/PageNotFound.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    // amikor maradunk oldalon belül, pl keresőnél akkor nem akarunk a tetjére görgetni, mivel az komponens fog a lista tetejére görgetni.
    if (to.name === from.name) {
      return
    }
    // Engedélyezd a scroll helyreállítását a böngészőben
    // a savedPosition nem használható, mivel route-link esetén nem értelmezett a pozició:
    // https://router.vuejs.org/guide/advanced/scroll-behavior.html
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'auto'
    }

    // viszont minden új oldal elestén - ami nem backward vagy forward - az oldal tetejére görgetünk.
    return {
      left: 0,
      top: 0,
    }
  },
  routes: routes,
})

export default router
