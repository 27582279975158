<template>
  <div>
    <div class="page-top" />
    <div class="d-none d-xl-flex align-items-center top-navbar custom-max-width m-auto">
      <div class="w-100 d-flex justify-content-between">
        <router-link to="/" class="brand-logo-container">
          <img class="brand-logo" :src="env.HEADER_IMAGE_RELATIVE_PATH + '?v=20240507'" :alt="env.LOGO_IMAGE_ALT">
        </router-link>
        <div class="phone-number-container d-flex">
          <i class="bi bi-telephone-fill top-navbar-icon me-2 d-flex align-items-center" />
          <a :href="'tel:' + PHONE_NUMBER" class="top-navbar-text cursor-pointer" v-text="PHONE_NUMBER" />
        </div>
        <router-link :to="getContactUrl" class="contact-btn-container d-flex align-items-center">
          <span v-t="'navigation_header.contact.label'" class="top-navbar-text cursor-pointer" />
        </router-link>
        <router-link :to="{ name: pageName.FAVORITE_PLACE_LIST_PAGE }" class="fav-places-container d-flex">
          <i class="bi bi-heart-fill top-navbar-icon me-2 d-flex align-items-center" />
          <span v-t="'navigation_header.favorite_locations.label'" class="top-navbar-text cursor-pointer" />
        </router-link>
        <div class="search-input-container top-navbar-text">
          <div class="input-group autocomplete-input">
            <AutocompleteSearchInput
              search-on-click
              @select="autocompleteSearchClickHandler"
              @change="autocompleteSearchChangeHandler"
              @enter="searchHandler"
            />
            <span class="input-group-append">
              <button class="btn bg-white ms-n5 search-btn" type="button" @click="searchHandler">
                <img class="search-icon" src="/assets/search_icon.png">
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobil nézet -->
    <nav v-if="menuListStore.getIsLoaded" class="navbar navbar-expand-xl">
      <router-link to="/" class="d-xl-none d-flex align-items-center top-navbar">
        <img class="brand-logo" :src="env.HEADER_IMAGE_RELATIVE_PATH" :alt="env.LOGO_IMAGE_ALT">
      </router-link>
      <button
        class="navbar-toggler border-0"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="openNavbarHandler"
      >
        <span v-if="!isNavbarOpen" class="navbar-toggler-icon" />
        <span v-else class="btn-close navbar-close-icon" />
      </button>
      <div id="navbarSupportedContent" class="collapse navbar-collapse justify-content-center mt-2 mx-auto custom-max-width" @transitionend="onTransitionEnd">
        <div class="row d-xl-none d-flex mb-6">
          <div class="col-6 d-flex">
            <div class="phone-number-container d-flex">
              <i class="bi bi-telephone-fill top-navbar-icon me-2 d-flex align-items-center" />
              <a :href="'tel:' + PHONE_NUMBER" class="top-navbar-text cursor-pointer" v-text="PHONE_NUMBER" />
            </div>
          </div>
          <div class="col-6">
            <div class="search-input-container top-navbar-text">
              <div class="input-group">
                <AutocompleteSearchInput
                  search-on-click
                  @select="autocompleteSearchClickHandler"
                  @change="autocompleteSearchChangeHandler"
                  @enter="searchHandler"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row d-xl-none d-flex mb-6">
          <div class="col-6">
            <router-link :to="{ name: pageName.TEAM_PAGE }" class="contact-btn-container d-flex align-items-center" @click="closeNavMenu">
              <span v-t="'navigation_header.contact.label'" class="top-navbar-text cursor-pointer" />
            </router-link>
          </div>
          <div class="col-6">
            <router-link :to="{ name: pageName.FAVORITE_PLACE_LIST_PAGE }" class="fav-places-container d-flex" @click="closeNavMenu">
              <i class="bi bi-heart-fill top-navbar-icon me-2 d-flex align-items-center" />
              <span v-t="'navigation_header.favorite_locations.label'" class="top-navbar-text cursor-pointer" />
            </router-link>
          </div>
        </div>
        <div class="row d-xl-none d-flex mb-4">
          <div class="col-12 d-flex justify-content-center">
            <CustomButton
              :title="$t('navigation_header.custom-request.btn.title')"
              :route="pageName.NEW_OFFER_PAGE"
              @click="closeNavMenu"
            />
          </div>
        </div>
        <div class="d-none d-xl-flex flex-wrap desktop-navbar">
          <ul v-for="(menuItem, index) in menuListStore.getMenuItems" :key="index" class="navbar-nav mr-auto">
            <li v-if="menuItem.subMenus !== undefined && menuItem.subMenus.length !== 0" class="nav-item dropdown">
              <router-link
                class="nav-link dropdown-toggle cursor-pointer"
                :class="{'mx-2': index !== 0}"
                :to="getUrl(menuItem.link)"
              >
                {{ menuItem.title }}
                <ul
                  class="dropdown-menu position-absolute"
                >
                  <li
                    v-for="(submenuItem, index2) in menuItem.subMenus"
                    :key="index2"
                    class="px-2 py-1"
                  >
                    <router-link class="dropdown-item" :to="getUrl(submenuItem.link)">
                      {{ submenuItem.title }}
                    </router-link>
                  </li>
                </ul>
              </router-link>
            </li>
            <li v-else class="nav-item">
              <router-link class="nav-link cursor-pointer mx-2" :to="getUrl(menuItem.link)">
                {{ menuItem.title }}
              </router-link>
            </li>
          </ul>
          <CustomButton
            :title="$t('navigation_header.custom-request.btn.title')"
            :route="pageName.NEW_OFFER_PAGE"
          />
        </div>
        <div class="d-xl-none d-flex">
          <div class="accordion mb-3 w-100">
            <div v-for="(menuItem, index) in menuListStore.getMenuItems" :key="index">
              <div class="accordion-item">
                <div class="accordion-header d-flex">
                  <router-link
                    class="accordion-title d-flex align-items-center w-100 cursor-pointer"
                    :to="getUrl(menuItem.link)"
                    @click="closeNavMenu"
                  >
                    {{ menuItem.title }}
                  </router-link>
                  <button
                    v-if="menuItem.subMenus !== undefined && menuItem.subMenus.length !== 0"
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    :data-bs-target="'#collapse-' + index"
                    :aria-controls="'collapse-' + index"
                  />
                </div>
                <div v-if="menuItem.subMenus !== undefined && menuItem.subMenus.length !== 0">
                  <div :id="'collapse-' + index" class="accordion-collapse collapse">
                    <router-link
                      v-for="(submenuItem, index2) in menuItem.subMenus"
                      :key="index2"
                      class="accordion-body"
                      :to="getUrl(submenuItem.link)"
                      @click="closeNavMenu"
                    >
                      <span class="accordion-subtitle" v-text="submenuItem.title" />
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { useMenuList } from '@/store/MenuList.js'
import { useFavoritePlaceList } from '@/store/FavoritePlaceList'
import pageName from '@/constants/pageName.js'
import CustomButton from '@/components/custom-button/CustomButton.vue'
import AutocompleteSearchInput from '@/components/autocomplete-search-input/AutocompleteSearchInput.vue'
import { SEARCH_TYPES } from '@/constants/search.js'
import { useRuntimeEnvironment } from '@shared/components/runtime-environment/RuntimeEnvironment.js'
import env from '@shared/vite/env.js'

const PHONE_NUMBER = '+36 30 900 9870'
export default {
  name: 'NavigationHeader',
  components: {
    AutocompleteSearchInput,
    CustomButton,
  },
  setup() {
    const menuListStore = useMenuList()
    const favoritePlaceList = useFavoritePlaceList()

    return { menuListStore, favoritePlaceList }
  },
  data() {
    return {
      env,
      /**
       * nyitva van-e navbar. Mobilon default false.
       */
      isNavbarOpen: false,
      /**
       * animál-e éppen a collapse
       */
      isCollapseAnimating: false,
      PHONE_NUMBER: PHONE_NUMBER,
      pageName: pageName,
      /**
       * Helyszín/Régió/Város
       *
       * @type {string}
       */
      searchText: '',
      /**
       * Keresés tipusa, lehet: 'freeText', 'region', 'featuredCity', 'city'
       *
       * @type {string}
       */
      searchType: SEARCH_TYPES.SEARCH_TYPE_FREETEXT,
    }
  },
  computed: {
    /**
     * Kapcsolat url meghatározása a megtekintett oldal függvényében.
     * @returns {string}
     */
    getContactUrl() {
      if (useRuntimeEnvironment().isMice()) {
        return '/site/contact'
      }
      return '/oldal/kapcsolat'
    },
  },
  mounted() {
    this.menuListStore.load()
    this.favoritePlaceList.load()
  },
  methods: {
    /**
     * Menü bezárás router-linkre kattintáskor
     */
    closeNavMenu() {
      document.getElementById('navbarSupportedContent').classList.remove('show')
      this.isNavbarOpen = false
    },
    /**
     * Keresés kezelése, paraméterek beállítása.
     * @param selectedItem
     */
    autocompleteSearchClickHandler(selectedItem) {
      this.searchText = selectedItem.name
      this.searchType = selectedItem.type
      this.searchHandler()
    },
    /**
     * ha átírja az autocomplete kereső inputot, akkor az szabadszavas lesz
     *
     * @param {string} value
     */
    autocompleteSearchChangeHandler(value) {
      this.searchText = value
      this.searchType = SEARCH_TYPES.SEARCH_TYPE_FREETEXT
    },
    /**
     * Keresés kezelése, paraméterek beállítása.
     */
    searchHandler() {
      const query = {}
      if (this.searchText !== '') {
        query.searchText = this.searchText
      }
      query.searchType = this.searchType

      this.$router.push({ name: pageName.PLACE_LIST_PAGE_ALL, params: { slug: 'kereses' }, query: query })
    },
    /**
     * Navbar kinyitás és bezárás lekezelése. Ez határozza meg a megjelenő ikont.
     * Mobil nézetben használjuk ki.
     * Csak akkor kell módositani, ha már vége az előző animációnak.
     */
    openNavbarHandler() {
      if (this.isCollapseAnimating) {
        return
      }

      this.isCollapseAnimating = true
      this.isNavbarOpen = !this.isNavbarOpen
    },
    /**
     * Akkor fut amikor végbement a collapse esemény.
     * Ennek függvényében állítjuk az ikont és az animációt tényét
     */
    onTransitionEnd() {
      this.isCollapseAnimating = false
    },
    /**
     * Url kiszámolása, az alapján, hogy külső vagy belső.
     * @param {string} link
     * @return {*}
     */
    getUrl(link) {
      if (link.includes(window.location.origin)) {
        return link.replace(window.location.origin, '')
      }
      return link
    },
  },
}

</script>

<style scoped lang="scss">
@import "../../assets/scss/theme/variables";
  .custom-max-width {
    max-width: 80rem;
  }

  a {
    text-decoration: none;
    color: #000;
  }

  .page-top {
    height: 0.625rem;
    background-color: var(--main-theme-color);
  }

  .brand-logo {
    cursor: pointer;
  }

  .desktop-navbar {
    max-width: 80rem;
    width: 100%;
    justify-content: space-between;
  }

  .nav-item, .dropdown {
    height: 2.6rem;
  }

  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0.938rem;
  }

  @media screen and (min-width: 75rem) {
    .top-navbar {
    height: 3.5rem;
    background-color: #fff;

      .brand-logo {
        width: 17rem;
        height: 5rem;
      }

      .brand-logo-container {
        width: 31.25rem;
      }

      .phone-number-container {
        width: 10.625rem;
      }

      .contact-btn-container {
        width: 6.25rem;
      }

      .fav-places-container {
        width: 12.5rem;
      }

      .search-input-container {
        width: 16.25rem;
      }
    }
  }

  .accordion-header {
    min-height: 52px;
  }

  .top-navbar-icon {
    color: var(--main-theme-color);
    font-size: 1.188rem;
  }

  .navbar {
    height: 4rem;
    background-color: #efefef;
  }

  .navbar-nav {
    position: relative;
  }

  .navbar-close-icon {
    width: 1.063rem;
    height: 1.063rem;
    display: flex;
  }

  .top-navbar-text {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.063rem;
    display: flex;
    align-items: center;
    color: #606060;
  }

  .nav-link {
    font-size: 0.938rem;
    font-weight: 600;
    color: #606060;
    line-height: 1.125rem;
    white-space: nowrap;
  }

  .navbar-nav:first-child {
    .nav-link {
      padding-left: 0;
    }
  }

  .dropdown-toggle::after {
    display: block;
    margin: auto;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    width: max-content;
    position: relative;
    top: 0.313rem;
    color: var(--main-theme-color);
  }

  .dropdown-item {
    font-size: 0.938rem;
    color: #606060
  }

  .dropdown-item:active {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg);
  }

  .dropdown-item:hover {
    background-color: #fff;
  }

  .custom-request-btn {
    color: #fff;
    background-color: var(--main-theme-color);
    border-radius: 0.25rem;
    padding: 0.625rem;
    width: 12.313rem;
    height: 2.5rem;
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 600;
    align-items: flex-end;
    line-height: 1.063rem;
    white-space: nowrap;
  }

  .custom-request-btn:hover {
    background-color: #2f9f9c;
    color: #fff;
  }

  @media screen and (max-width: 75rem) {
    .page-top {
      height: 2.5rem;
    }

    .navbar {
      height: unset;
      background-color: #fff;
      padding: 0.313rem 0.938rem;
    }

    .brand-logo {
      width: 15rem;
      height: 3.4375rem;
    }

    .custom-request-btn {
      max-width: 22.5rem;
      box-shadow: 0 0.625rem 0.938rem -0.188rem #0000001A;
    }

    .accordion-item {
      border-left: 0;
      border-right: 0;
    }

    .accordion-collapse {
      border-top: 0.063rem solid var(--bs-border-color);
    }

    .accordion-button, .accordion-button:focus {
      box-shadow: none;
      width: fit-content;
      border-left: 0.063rem solid var(--bs-border-color);
      // csak igy lehet felülírni a bs default radiust.
      border-radius: 0 !important;
    }

    .accordion-button:not(.collapsed) {
      background-color: #fff;
      color: #000;
      border-radius: 0 !important;
    }

    .accordion-body {
      width: 100%;
      display: block;
      cursor: pointer;
    }

    .accordion-title {
      line-height: 1.25rem;
      font-size: 1rem;
      font-weight: 600;
    }

    .accordion-subtitle {
      line-height: 1.125rem;
      font-weight: 500;
      font-size: 0.938rem;
    }
  }

  .search-input {
    // input default 0ra állítja, ezt írom felül az importanttal.
    border-radius: 0.25rem !important;
  }

  .search-btn {
    right: 0.625rem;
    margin: 0.063rem;
    padding: 0.313rem;
    color: var(--main-theme-color);
  }

  .autocomplete-input {
    :deep(.autocomplete-list) {
      top: 34px;
    }
  }

  .search-icon {
    cursor: default;
  }

  .input-group-append {
    width: 0;
  }
</style>
