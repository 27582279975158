import { defineStore } from 'pinia'
import Axios from 'axios'
import { useApiErrorHandler } from '@/components/api-error-handler/ApiErrorHandler.js'

export const useMenuList = defineStore('menuList', {
  state: () => ({
    /**
     * Menü elemek
     *
     * @return {{
     *  id: number,
     *  link: string,
     *  title: string,
     *  subMenus?: [{
     *    id: number,
     *    link: string,
     *    title: string,
     *  }]
     * }[]}
     */
    menuItems: [],
    /**
     * Menu elemek betöltése megtörtént-e
     */
    loaded: false,
  }),
  getters: {
    getMenuItems: state => {
      return state.menuItems
    },
    getIsLoaded: state => {
      return state.loaded
    },
  },
  actions: {
    load() {
      return Axios.get('/api/public/menus/list')
        .then(({ data }) => {
          this.menuItems = data
        })
        .catch((error) => {
          const errorHandler = useApiErrorHandler()
          errorHandler.apiNotFoundHandler(error, this.$router, this.$route)
        })
        .finally(() => {
          this.loaded = true
        })
    },
  },
})
