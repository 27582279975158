import RuntimeEnvs from '../../constants/RuntimeEnvs.js'
import env from '@shared/vite/env.js'

export function useRuntimeEnvironment() {
  /**
   * Fejlesztői környezet-e
   */
  function isDevel() {
    return env.APP_ENV === RuntimeEnvs.DEV_CHH || env.APP_ENV === RuntimeEnvs.DEV_MICE
  }

  /**
   * Éles környezet-e
   */
  function isProd() {
    return env.APP_ENV === RuntimeEnvs.PROD_CHH || env.APP_ENV === RuntimeEnvs.PROD_MICE
  }

  /**
   * Teszt környezet-e
   */
  function isTest() {
    return env.APP_ENV === RuntimeEnvs.TEST_CHH || env.APP_ENV === RuntimeEnvs.TEST_MICE
  }

  /**
   * Mice oldalt használunk-e, környezet független(dev, teszt, prod)
   * @returns {boolean}
   */
  function isMice() {
    return env.APP_ENV === RuntimeEnvs.DEV_MICE || env.APP_ENV === RuntimeEnvs.TEST_MICE || env.APP_ENV === RuntimeEnvs.PROD_MICE
  }

  /**
   * CHH oldalt használunk-e, környezet független(dev, teszt, prod)
   * @returns {boolean}
   */
  function isCHH() {
    return env.APP_ENV === RuntimeEnvs.DEV_CHH || env.APP_ENV === RuntimeEnvs.TEST_CHH || env.APP_ENV === RuntimeEnvs.PROD_CHH
  }

  /**
   * Visszaadja az alkalmazás verzióját (buildVersion.json alapján)
   *
   * @returns {String}
   */
  function getVersion() {
    /* global __VERSION__ */
    return __VERSION__
  }

  return {
    isTest,
    isDevel,
    isProd,
    getVersion,
    isMice,
    isCHH,
  }
}
