<template>
  <div class="row footer-container">
    <div class="col-12 col-xl-4 d-flex justify-content-center align-items-center flex-column mb-3">
      <router-link to="/">
        <img class="brand-logo" :src="env.FOOTER_IMAGE_RELATIVE_PATH + '?v=20240507'" :alt="env.LOGO_IMAGE_ALT">
      </router-link>
      <img
        v-if="env.BUSINESS_EXCELLENCE_IMAGE_VISIBLE === 'yes'"
        class="business-excellence-logo"
        src="/assets/Business-Excellence_2019.png"
        alt="business_excellence_2019"
      >
    </div>
    <div v-if="footerLoaded" class="col-12 col-xl-8 d-flex justify-content-center flex-column">
      <div class="row">
        <div v-for="column in 4" :key="column" class="d-flex col-12 col-xl-3 flex-column">
          <div v-if="footerItems[column].length !== 0">
            <div v-for="(item, key) in footerItems[column]" :key="key" class="footer-block">
              <router-link v-if="!isInnerFileUrl(item.link)" :to="getLink(item.link)">
                <span class="footer-text" v-text="item.name" />
              </router-link>
              <a
                v-else
                :href="getLink(item.link)"
                target="_blank"
              >
                <span class="footer-text" v-text="item.name" />
              </a>
            </div>
          </div>
          <div v-if="column === 4">
            <div class="col-12 d-flex justify-content-center w-100">
              <a class="social-icon" :href="env.LINKEDIN_LINK" target="_blank">
                <i class="bi bi-linkedin" />
              </a>
              <a
                v-if="env.FACEBOOK_LINK"
                class="social-icon"
                :href="env.FACEBOOK_LINK"
                target="_blank"
              >
                <i class="bi bi-facebook social-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios'
import pageName from '@/constants/pageName.js'
import env from '@shared/vite/env.js'

export default {
  name: 'MainFooter',
  data() {
    return {
      env,
      /**
       * Footer elemek oszlop bontásban.
       * @typedef {{
       *   1: {
       *     name: String,
       *     link: String,
       *   }[],
       *   2: {
       *     name: String,
       *     link: String,
       *   }[],
       *   3: {
       *     name: String,
       *     link: String,
       *   }[],
       *   4: {
       *     name: String,
       *     link: String,
       *   }[],
       * }}
       */
      footerItems: [],
      /**
       * Betöltöttek-e a footer adatok.
       */
      footerLoaded: false,
      pageName,
    }
  },
  mounted() {
    this.listFooterItems()
  },
  methods: {
    /**
     * Url kiszámolása, az alapján, hogy külső vagy belső.
     * @param {string} link
     * @return {*}
     */
    getLink(link) {
      if (link.includes(window.location.origin)) {
        return link.replace(window.location.origin, '')
      }
      return link
    },
    /**
     * Ellenőrízzük, hogy feltöltött fájlt nyitunk-e meg.
     * Ezt abból tudjuk, hogy a linkben van '.' ami a kiterjeszés miatt van.
     * @param {string} link
     */
    isInnerFileUrl(link) {
      return link.includes('.')
    },
    /**
     * Footer adatok betöltése.
     */
    listFooterItems() {
      Axios.get('/api/public/footer/list')
        .then(({ data }) => {
          this.footerItems = data
        })
        .finally(() => {
          this.footerLoaded = true
        })
    },
  },
}

</script>

<style scoped lang="scss">
a {
  text-decoration: none;
  color: #000;
}

.footer-container {
  max-width: 80rem;
  min-height: 15.875rem;
  margin: auto;
  border-top: 0.063rem solid #cfcfcf;
  padding: 3.125rem 0 3.75rem 0;

}

.brand-logo {
  cursor: pointer;
  width: 13.75rem;
  height: 3.5rem;
  position: relative;
  bottom: 0.75rem;
}

.business-excellence-logo {
  width: 13.75rem;
}

.footer-block {
  gap: 0.5rem;
  margin-bottom: 0.625rem;
}

.footer-text {
  font-size: 0.825rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.02em;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.footer-text:hover {
  text-decoration: underline;
}

.social-icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.313rem;
  cursor: pointer;
}
</style>
