<template>
  <router-link :to="getRouteOrLink">
    <button
      class="btn custom-btn"
      v-text="title"
    />
  </router-link>
</template>

<script>

export default {
  name: 'CustomButton',
  props: {
    route: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      default: null,
    },
  },
  computed: {
    getRouteOrLink() {
      if (this.route !== null) {
        return {
          name: this.route,
        }
      }

      if (this.link.includes(window.location.origin)) {
        return this.link.replace(window.location.origin, '')
      }
      return this.link
    },
  },
}
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

a {
  text-decoration: none;
}

.custom-btn {
  font-weight: 600;
  font-family: Montserrat, serif;
  color: #fff;
  background-color: var(--main-theme-color);
  border-radius: 0.25rem;
  padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
  height: 2.5rem;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  align-items: flex-end;
  line-height: 1.063rem;
  white-space: nowrap;
  width: fit-content;
  letter-spacing: 0.0625rem;
  text-align: center;
  box-shadow: 0 0.625rem 0.9375rem -0.1875rem #0000001A;
}

.custom-btn:hover {
  background-color: #2f9f9c;
  color: #fff;
}

@include media-breakpoint-down(md) {
  .custom-btn {
    height: fit-content;
    white-space: break-spaces;
  }
}
</style>
