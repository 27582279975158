export default {
  ARTICLE_LIST_PAGE: 'ArticleListPage',
  ARTICLE_LIST_WITH_TAG_PAGE: 'ArticleListWithTagPage',
  ARTICLE_PAGE: 'ArticlePage',
  HOME: 'PublicHomePage',
  TILED_PAGE: 'PublicTiledPage',
  PLACE_EDIT: 'PlaceEdit',
  PLACE_PAGE: 'PlacePage',
  PAGE_NOT_FOUND: 'PageNotFound',
  FAVORITE_PLACE_LIST_PAGE: 'FavoritePlaceListPage',
  CONTENT_PAGE: 'ContentPage',
  NEWSLETTER_SUBSCRIBE_PAGE: 'NewsletterSubscribePage',
  PLACE_LIST_PAGE_ALL: 'PlaceListPageAll',
  PLACE_LIST_PAGE_TYPE: 'PlaceListPageType',
  PLACE_LIST_PAGE_TYPE_CITY: 'PlaceListPageTypeCity',
  PLACE_LIST_PAGE_NATURE: 'PlaceListPageNature',
  PLACE_LIST_PAGE_COUNTRY: 'PlaceListPageCountry',
  PLACE_LIST_PAGE_COUNTRY_REGION: 'PlaceListPageCountryRegion',
  PLACE_LIST_PAGE_COUNTRY_REGION_TYPE: 'PlaceListPageCountryRegionType',
  PLACE_PREDEFINED_LIST: 'PlacePredefinedList',
  PLACE_REGISTRATION_PAGE: 'PlaceRegistrationPage',
  PLACE_REGISTRATION_THANK_YOU_PAGE: 'PlaceRegistrationThankYouPage',
  NEW_OFFER_PAGE: 'NewOfferPage',
  NEW_OFFER_PAGE_WITH_PLACE_ID: 'NewOfferPageWithPlaceId',
  SUCCESSFUL_OFFER_PAGE: 'SuccessfulOfferPage',
  NEWSLETTER_SUBSCRIBE_PAGE_FOR_PLACE: 'NewsletterSubscribePageForPlace',
  TEAM_PAGE: 'TeamPage',
}
