import enConfig from './en.json'
import huConfig from './hu.json'
import { useRuntimeEnvironment } from '@shared/components/runtime-environment/RuntimeEnvironment'
import { query } from 'vue-gtag'

const LABEL_GRANTED = 'granted'
const LABEL_DENIED = 'denied'

/**
 * All config. options available here:
 * https://cookieconsent.orestbida.com/reference/configuration-reference.html
 *
 * @type {import('vanilla-cookieconsent').CookieConsentConfig}
 */
export default {
  categories: {
    necessary: {
      enabled: true, // this category is enabled by default
      readOnly: true, // this category cannot be disabled
    },
    analytics: {
      enabled: true,
      readOnly: false,
    },
    marketing: {
      enabled: true,
      readOnly: false,
    },
  },

  // kikapcsol minden oldal interakciót amig aktiv a consent modal.
  disablePageInteraction: true,

  cookie: {
    // Hetente újra meg kell majd erősíteni a consentet a usernek.
    expiresAfterDays: 7,
  },

  guiOptions: {
    consentModal: {
      layout: 'box wide',
      position: 'middle center',
    },
  },

  language: {
    default: useRuntimeEnvironment().isMice() ? 'en' : 'hu',
    translations: {
      en: {
        ...enConfig,
      },
      hu: {
        ...huConfig,
      },
    },
  },

  // Ez az esemény akkor lép életbe, amikor a felhasználó először fejezi ki a beleegyezését, de minden további oldalbetöltéskor is.
  // Bővebben callback eseményekről:  https://cookieconsent.orestbida.com/advanced/callbacks-events.html
  onConsent: ({ cookie }) => {
    const data = {
      ad_storage: cookie.categories.includes('marketing') ? LABEL_GRANTED : LABEL_DENIED,
      analytics_storage: cookie.categories.includes('analytics') ? LABEL_GRANTED : LABEL_DENIED,
    }

    query('consent', 'update', data)
  },

  /**
   * @param {String} modalName - 'consentModal' vagy 'preferencesModal'
   */
  onModalShow: ({ modalName }) => {
    // gömbök színének beállításához css osztályok hozzáadása az elementhez.
    if (modalName === 'consentModal') {
      const cmButtonGroup = document.getElementsByClassName('cm__btn-group')
      cmButtonGroup[0].childNodes[1].classList.add('reject-all-btn')
      cmButtonGroup[0].childNodes[0].classList.add('accept-all-btn')
    } else {
      const pmButtonGroup = document.getElementsByClassName('pm__btn-group')
      pmButtonGroup[0].childNodes[1].classList.add('reject-all-btn')
      pmButtonGroup[0].childNodes[0].classList.add('accept-all-btn')
    }
  },
}
