<template>
  <router-view />
  <alerts :alerts="alerts" />
</template>

<script>
import Alerts from '@/components/alerts/Alerts.vue'
import { Tooltip } from 'bootstrap'

export default {
  name: 'App',
  components: {
    Alerts,
  },
  data() {
    return {
      /**
       * Alert items
       */
      alerts: [],
      /**
       * Bootstrap tooltip globális init változó
       */
      bsTooltip: null,
    }
  },
  mounted() {
    // Globálisan bekapcsoljuk a tooltip használatot.
    // https://getbootstrap.com/docs/5.3/components/tooltips/#enable-tooltips
    this.bsTooltip = new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
    this.$emitter.on('notify', this.onNotify)
  },
  methods: {
    /**
     * Notify event
     *
     * @param data
     */
    onNotify(data) {
      this.alerts.push(data)
    },
  },
}
</script>
