import en from './lang/en.json'
import hu from './lang/hu.json'
import sharedEn from '@shared/messages/lang/en.json'
import sharedHu from '@shared/messages/lang/hu.json'

export default {
  en: {
    ...en,
    ...sharedEn,
  },
  hu: {
    ...hu,
    ...sharedHu,
  },
}
