const metaEnv = import.meta.env

// Merge envs (meta for build envs, window for runtime envs)
const env = { ...window.env, ...metaEnv }

export default {
  // Default envs:
  BASE_URL: env.BASE_URL,
  DEV: env.DEV,
  MODE: env.MODE,
  PROD: env.PROD,
  SSR: env.SSR,
  // Egyedi admin envek (FRONTEND_ADMIN_*):
  // ...
  // Egyedi website envek (FRONTEND_WEBSITE_*):
  LANG: env.FRONTEND_WEBSITE_LANG,
  GOOGLE_MAP_API_KEY: env.FRONTEND_WEBSITE_GOOGLE_MAP_API_KEY,
  RECAPTCHA_SITE_KEY: env.FRONTEND_WEBSITE_RECAPTCHA_SITE_KEY,
  GTM_ID: env.FRONTEND_WEBSITE_GTM_ID,
  // Általános frontend envek (FRONTEND_SHARED_*):
  API_URL: env.FRONTEND_ADMIN_API_URL || env.FRONTEND_WEBSITE_API_URL, // pontosan az egyik van, attó függően melyik app fut. todo: átírni FRONTEND_SHARED_API_URL-envre mindkét helyen!
  SENTRY_DSN: env.FRONTEND_SHARED_SENTRY_DSN,
  APP_ENV: env.FRONTEND_SHARED_APP_ENV,
  BUSINESS_EXCELLENCE_IMAGE_VISIBLE: env.FRONTEND_WEBSITE_BUSINESS_EXCELLENCE_IMAGE_VISIBLE,
  META_TITLE_SUFFIX: env.FRONTEND_WEBSITE_META_TITLE_SUFFIX,
  HEADER_IMAGE_RELATIVE_PATH: env.FRONTEND_SHARED_HEADER_IMAGE_RELATIVE_PATH,
  FOOTER_IMAGE_RELATIVE_PATH: env.FRONTEND_SHARED_FOOTER_IMAGE_RELATIVE_PATH,
  LOGO_IMAGE_ALT: env.FRONTEND_SHARED_LOGO_IMAGE_ALT,
  LINKEDIN_LINK: env.FRONTEND_WEBSITE_LINKEDIN_LINK,
  FACEBOOK_LINK: env.FRONTEND_WEBSITE_FACEBOOK_LINK,
}
