import { ref } from 'vue'
import { useCookies } from 'vue3-cookies'
import { useRuntimeEnvironment } from '@shared/components/runtime-environment/RuntimeEnvironment.js'
const { cookies } = useCookies()

export function useLang() {
  const LANG_HU = 'hu'
  const LANG_EN = 'en'
  const LANG_CZ = 'cz'
  const COOKIE_LANG_KEY = 'lang'
  const COOKIE_SITE_LANG_KEY = 'siteLang'

  const storedAdminLang = getStoredAdminLang()
  const storedSiteLang = getStoredSiteLang()
  const langSite = ref(storedSiteLang !== null ? storedSiteLang : getSiteLang())
  const langAdmin = ref(storedAdminLang !== null ? storedAdminLang : LANG_HU)

  /**
   * Admin nyelvinek lekérése cookieból
   * @returns {*}
   */
  function getStoredAdminLang() {
    return cookies.get(COOKIE_LANG_KEY)
  }

  /**
   * Site nyelvinek lekérése cookieból
   * @returns {*}
   */
  function getStoredSiteLang() {
    return cookies.get(COOKIE_SITE_LANG_KEY)
  }

  /**
   * Admin nyelv beállítása, Mice angol, CHH magyar.
   * @returns {string}
   */
  function getSiteLang() {
    if (useRuntimeEnvironment().isMice()) {
      return LANG_EN
    }

    return LANG_HU
  }

  /**
   * Admin nyelv cookieba mentése
   * @param {String} lang
   * @returns {*}
   */
  function setStoredAdminLang(lang) {
    return cookies.set(COOKIE_LANG_KEY, lang, -1)
  }

  /**
   * Site nyelv cookieba mentése.
   * @param {String} lang
   * @returns {*}
   */
  function setStoredSiteLang(lang) {
    return cookies.set(COOKIE_SITE_LANG_KEY, lang, -1)
  }

  /**
   * Admin oldal nyelvét menti le és ujra tölti az oldalt, hogy alkamazza a nyelvikulcsokat is.
   * @param {String} langParam
   */
  function setAdminLang(langParam) {
    langAdmin.value = langParam

    setStoredAdminLang(langParam)

    // változott a nyelv, újratöltjük az oldalt.
    location.reload()
  }

  /**
   * Site oldal nyelvét menti le, paraméter függően reload.
   * @param {String} langParam
   * @param {Boolean} reloadPage
   */
  function setLang(langParam, reloadPage = true) {
    langSite.value = langParam

    setStoredSiteLang(langParam)

    if (reloadPage) {
      // változott a nyelv, újratöltjük az oldalt.
      location.reload()
    }
  }

  return {
    langSite,
    langAdmin,
    LANG_HU,
    LANG_EN,
    LANG_CZ,
    getSiteLang,
    setLang,
    setAdminLang,
  }
}
