<template>
  <bs-input
    :id="id"
    v-model="searchText"
    class="form-control"
    :placeholder="$t('custom_search.input.location.label')"
    autocomplete="off"
    @input="locationInputChange($event.target.value)"
    @blur="onInputBlur"
    @focus="showList = locations.length !== 0"
    @keydown.enter.stop="onInputEnterPress"
  />
  <div v-if="showList" class="list-group autocomplete-list w-100">
    <div v-for="(location, index) in locations" :key="index">
      <div class="list-group-item group-title fw-bold" v-text="getAutocompleteBlockTitle(index)" />
      <div
        v-for="(item, key) in location"
        :key="key"
        class="list-group-item selectable-group-item"
      >
        <router-link v-if="item.link !== undefined" :to="getUrl(item.link)" class="list-item-name">
          {{ item.name }}
        </router-link>
        <div
          v-else
          class="list-item-name"
          @click="selectItemHandler(item)"
          v-text="item.name"
        />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import Axios from 'axios'
import { BsInput } from 'bootstrap-vue-wrapper'

const LOCATION_PLACES = 'places'
const LOCATION_REGION = 'regions'
const LOCATION_CITIES = 'cities'
const LOCATION_FEATURED_CITIES = 'featuredCities'

export default {
  name: 'AutocompleteSearchInput',
  components: {
    BsInput,
  },
  props: {
    /**
     * fejléc keresésnél kattintásnál egyőbl keres. de az inputba ilyenkor be se írjuk a szöveget
     * pl. 'Buda' esetén Budapest re keres, ha kattint, de a 'Buda' nem kerül az inputba.
     */
    searchOnClick: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    /**
     * Amikor az inputhoz tartozik label akkor a label for miatt van megadva id.
     * Más esetben viszont a bs-input label for és autofill miatt kell és kötelező, egyedi a htmlben - random érték.
     */
    id: {
      type: String,
      default: (Math.floor(Math.random() * 100) + 1).toString(),
    },
  },
  emits: [
    'select',
    'change',
    'enter',
  ],
  data() {
    return {
      /**
       * Helyszín/Régió/Város
       *
       * @type {string}
       */
      searchText: '',
      /**
       * helyszin/város/régió autocomplete elemek listája.
       * @type {{
       *   places: ?{
       *     name: string,
       *     link: string,
       *   },
       *   cities: ?{
       *     name: string,
       *     type: string,
       *   },
       *   regions: ?{
       *     name: string,
       *     type: string,
       *   },
       *   featuredCity: ?{
       *     name: string,
       *     type: string,
       *   },
       * }[]}
       */
      locations: [],
      /**
       * Látszódik-e az autocomplete kereső
       * @type {boolean}
       */
      showList: false,
    }
  },
  watch: {
    /**
     * watcher, mert a prop nem egyből állítódik be, a component betöltési sorrend miatt. ez nem ideális, de legalább egyszerű.
     * @param newValue
     */
    text(newValue) {
      this.searchText = newValue
    },
  },
  methods: {
    /**
     * Url kiszámolása, az alapján, hogy külső vagy belső.
     * @param {string} link
     * @return {*}
     */
    getUrl(link) {
      if (link.includes(window.location.origin)) {
        return link.replace(window.location.origin, '')
      }
      return link
    },
    /**
     * Lokáció kereső, amely a helyszinek, régiók és városok között is keres és visszatér egy ömlesztett, de rendezett listával.
     * Elsők a helyszinek, utána a városok és a régiók.
     */
    locationInputChange: debounce(function (value) {
      this.$emit('change', value)
      if (value === '') {
        this.locations = []
        return
      }
      this.listLocations(value)
    }, 400),
    /**
     * autocomplete blur esetén elrejtjük a listát
     */
    onInputBlur: debounce(function () {
      this.showList = false
    }, 200),
    /**
     * autocomplete blur esetén elrejtjük a listát
     */
    onInputEnterPress: debounce(function () {
      this.showList = false
      this.$emit('enter')
    }, 300),
    /**
     * Paraméter alapján visszaadj egy listát a helyszinekről, városokről és régiókról, amelyre illeszkedik a keresési szöveg
     * @param text
     */
    listLocations(text) {
      Axios.post('/api/public/place/listLocations', {
        searchText: text,
      })
        .then(({ data }) => {
          this.locations = data
          this.showList = this.locations.length !== 0
        })
        .catch(error => {
          console.error(error)
        })
    },
    /**
     * Több string index alapján meghatározzuk a blokk nevét.
     * @param {string} title
     */
    getAutocompleteBlockTitle(title) {
      switch (title) {
        case LOCATION_PLACES:
          return this.$t('custom_search.autocomplete_block.place.title')
        case LOCATION_REGION:
          return this.$t('custom_search.autocomplete_block.region.title')
        case LOCATION_CITIES:
          return this.$t('custom_search.autocomplete_block.city.title')
        case LOCATION_FEATURED_CITIES:
          return this.$t('custom_search.autocomplete_block.featured_city.title')
        default:
          console.error('Nem támogatott autocomplete blokk tipus')
      }
    },
    /**
     * Kezeljük a kiválasztott elemet, ha helyszin, aminek van direkt linkje, akkor megnyitjuk
     * @param {Object} selectedItem
     */
    selectItemHandler(selectedItem) {
      this.$emit('select', { name: selectedItem.name, type: selectedItem.type })

      if (this.searchOnClick) {
        // szabad szöveges keresésnél a city kattintás pl. nem szabad szöveges keresés többé.
        // ilyenkor kitöröljük! TODO: valójában a type nem freeText és azért kéne eltüntetni!
        this.searchText = ''
      } else {
        this.searchText = selectedItem.name
      }

      // autocomplete elrejtés
      this.showList = false
      this.locations = [] // reset (újra felnyitásnál üresről induljon!)
    },
  },
}

</script>

<style scoped lang="scss">
a {
  text-decoration: none;
  color: #000;
  font-weight: 400;
}

.autocomplete-list {
  position: absolute;
  box-shadow: 0 0.625rem 0.938rem -0.188rem rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  max-height: 31.25rem;
  z-index: 2;

  .list-group-item {
    border: unset;
    background-color: #fff;
    padding: 0;

    a {
      width: 100%;
      display: block;
      height: 100%;
      padding: 0.5rem 1.5rem;
    }
  }

  .selectable-group-item {
    cursor: pointer;
  }

  .group-title {
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  }

  .list-item-name {
    padding: 0.5rem 1.5rem;
    font-weight: 400;
  }

  .selectable-group-item:hover,
  .selectable-group-item:hover a,
  .selectable-group-item:focus,
  .selectable-group-item:focus a {
    background-color: var(--main-theme-color);
    color: #fff;
    font-weight: 600;
  }
}
</style>
