import { createApp } from 'vue'
import Axios from 'axios'
import App from './App.vue'
import router from './modules/router/router.js'
import './assets/scss/main.scss'
import 'bootstrap'
import { createI18n } from 'vue-i18n'
import messages from './messages/index.js'
import { createEmitter } from '@/modules/emitter/emitter.js'
import '@fontsource/inter/100.css'
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/900.css'
import '@fontsource/montserrat/100.css'
import '@fontsource/montserrat/300.css'
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/700.css'
import '@fontsource/montserrat/900.css'
import '~flag-icons/css/flag-icons.min.css'
import { createPinia } from 'pinia'
import env from '@shared/vite/env.js'
import { Vue3Mq } from 'vue3-mq'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { VueHeadMixin, createHead } from '@unhead/vue'
import * as Sentry from '@sentry/vue'
import { captureConsoleIntegration } from '@sentry/integrations'
import { useRuntimeEnvironment } from '@shared/components/runtime-environment/RuntimeEnvironment.js'
import { useLang } from '@shared/components/lang/Lang.js'
import VueGtag, { query } from 'vue-gtag'
import VueCookies, { useCookies } from 'vue3-cookies'

import CookieConsentVue from '@/modules/cookie-consent/CookieConsent.js'
import config from '@/modules/cookie-consent/CookieConsentConfig.js'

const { cookies } = useCookies()
const app = createApp(App)
const pinia = createPinia()
const head = createHead()

app.use(pinia)
app.use(VueCookies)
app.use(router)
app.use(createI18n({
  locale: useLang().langSite.value,
  fallbackLocale: 'hu',
  legacy: false,
  messages,
}))
app.use(createEmitter())
app.use(Vue3Mq)
app.use(VueReCaptcha, { siteKey: env.RECAPTCHA_SITE_KEY, loaderOptions: { autoHideBadge: true } })
app.mixin(VueHeadMixin)
app.use(head)
// csak prodon akarjuk behuzni egyenlőre.
if (env.GTM_ID !== '') {
  // https://matteo-gabriele.gitbook.io/vue-gtag/auto-tracking#enable-auto-tracking
  app.use(VueGtag, {
    config: {
      id: env.GTM_ID,
    },
  }, router)

  app.use(CookieConsentVue, config)

  // cookie consent default értékek beállítása.
  // Kérésre minden engedélyezett alap esetben is.
  query('consent', 'default', {
    ad_storage: 'granted',
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    analytics_storage: 'granted',
  })
}

if (env.SENTRY_DSN) {
  Sentry.init({
    app,
    environment: env.APP_ENV,
    release: useRuntimeEnvironment().getVersion(), // Megegyezik a buildelésnél megadott sourcemap release-el
    dsn: env.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({
        router: router,
      }),
      Sentry.replayIntegration(),
      captureConsoleIntegration(), // console.log/warn/error elkapása
    ],
    // 100%, az összes tranzakcióról készül felvétel
    tracesSampleRate: 1.0,
    // https://develop.sentry.dev/sdk/performance/#tracepropagationtargets
    tracePropagationTargets: ['chh.127.0.0.1.nip.io', 'cegeshelyszinek.hu', 'test.cegeshelyszinek.hu', /^\//],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    trackComponents: true,
  })
}

// ma még nincs beállítva akkor létrehozunk egy default site nyelvü cookiet.
if (cookies.get('siteLang') === null) {
  cookies.set('siteLang', useLang().langSite.value)
}

// Axios default config
Axios.defaults.baseURL = env.API_URL + '/' + cookies.get('siteLang')

app.mount('#app')
